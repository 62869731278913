import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PortfolioLayout from '../components/portfolioLayout';

const Portfolio = () => {
    const data = useStaticQuery(graphql`
    query {
      aBefore: file(relativePath: { eq: "20200814-01-before.jpg" }) {
        childImageSharp {
          fluid (maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      aAfter: file(relativePath: { eq: "20200814-03-after.jpg" }) {
        childImageSharp {
          fluid (maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      bBefore: file(relativePath: { eq: "2020-11-entry-before.jpg" }) {
        childImageSharp {
          fluid (maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      bAfter: file(relativePath: { eq: "2020-11-entry-after.jpg" }) {
        childImageSharp {
          fluid (maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      dBefore: file(relativePath: { eq: "2020-11-back-before.jpg" }) {
        childImageSharp {
          fluid (maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      dAfter: file(relativePath: { eq: "2020-11-back-after.jpg" }) {
        childImageSharp {
          fluid (maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      files: allFile(
        filter: { sourceInstanceName: { eq: "portfolio" }, relativeDirectory: { eq: "siding" } },
        sort: { fields: [ name ] }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed (width: 400){
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);
  
  const beforeAfter = [
      {
        'before': data.aBefore,
        'after': data.aAfter
      },
      {
        'before': data.bBefore,
        'after': data.bAfter
      },
      {
        'before': data.dBefore,
        'after': data.dAfter
      },
    ];

  return (
    <PortfolioLayout 
      title='Siding'
      path='portfolio-siding'
      data={data.files.edges}
      link='/vinyl-siding-contractor'
      beforeAfter={beforeAfter}
      />
)};

export default Portfolio;
